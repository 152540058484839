import { call, put, takeLatest } from "redux-saga/effects";
import { PatientsAPI } from "../../../api/patientsAPI";
import { ToastType } from "../../../utils/interfaces";
import {
  APPLY_TREATMENT_PLAN_REQUEST,
  CREATE_PATIENT_ERROR,
  CREATE_PATIENT_REQUEST,
  CREATE_PATIENT_SUCCESS,
  SET_TOAST
} from "../../actions";

function* workerCreatePatient(action: {
  type: string;
  payload: { patientData: any; applyPlanData: any; navigate: Function };
}): Generator<any> {
  try {
    const { patientData, applyPlanData, navigate } = action.payload;
    const response: any = yield call(PatientsAPI.createPatient, patientData);
    yield put({
      type: CREATE_PATIENT_SUCCESS
    });
    if (applyPlanData) {
      yield put({
        type: APPLY_TREATMENT_PLAN_REQUEST,
        payload: {
          data: { ...applyPlanData, patientId: response.id },
          navigate
        }
      });
    } else {
      navigate(`/patients/view/${response.id}`);
    }
    yield put({
      type: SET_TOAST,
      payload: {
        open: true,
        message: "New patient is added successfully",
        type: ToastType.SUCCESS,
      }
    });
  } catch (error: any) {
    let errorMessage = '';

    switch(true) {
      case error?.response?.data?.includes("uc_sensitivedata_phone"):
        errorMessage = "The phone number is not unique. Please use another phone number";
        break;
      case error?.response?.data?.includes("uc_patient_externalid"):
        errorMessage = "The external id is not unique. Please use another external id";
        break;
      case error?.response?.data?.includes("should have unique value"):
        errorMessage = "The data is not unique. Please use another information";
        break;
      default:
        errorMessage = "Something went wrong, please check entered data and try again";
    }

    yield put({
      type: SET_TOAST,
      payload: {
        open: true,
        message: errorMessage,
        type: ToastType.ERROR,
      }
    });
    yield put({
      type: CREATE_PATIENT_ERROR,
      payload: { code: error.response.status, message: error.message }
    });
  }
}

export function* watcherCreatePatient() {
  yield takeLatest(CREATE_PATIENT_REQUEST, workerCreatePatient);
}
