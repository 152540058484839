import { CircularProgress, IconButton } from '@mui/material';
import classes from './ViewPatientControls.module.scss';
import { AlarmsMenu } from '../../../../../assets/icons/AlarmsMenu';
import { EditPatient } from '../../../../../assets/icons/EditPatient';
import { ExternalLink } from '../../../../../assets/icons/ExternalLink';
import { LeftChevron } from '../../../../../assets/icons/LeftChevron';
import { Report } from '../../../../../assets/icons/Report';
import { IViewPatientControls, ToastType } from '../../../../../utils/interfaces';
import { ActionButton } from './ActionButton/ActionButton';
import { DeletePatient } from '../../../../../assets/icons/DeletePatient';
import { Password } from '../../../../../assets/icons/Password';
import { NOT_ACTIVATED } from '../../../../../utils/constants';
import { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { ClickingDataAPI } from '../../../../../api/clickingDataAPI';
import { generateReport } from '../../../../../utils/viewPatientUtils';
import XLSX from 'xlsx';
import { useDispatch } from 'react-redux';
import { SET_TOAST } from '../../../../../redux/actions';
import {PatientsAPI} from '../../../../../api/patientsAPI';

export const ViewPatientControls = ({
  deletePatient,
  editPatient,
  externalLink,
  navigateBack,
  patientStatus,
  sendPassword,
  showAlarms,
  patient,
  activeNotificationCount,
}: IViewPatientControls) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const getReportData = async () => {
    if (!patient) return;

    try {
      setLoading(true);
      const result = await ClickingDataAPI.getClickingData(
        patient.id,
        patient.treatmentPlan.startDate,
        patient.treatmentPlan.endDate,
      );

      const exercisesResult = await PatientsAPI.getExercisePerformance({
        id: patient.id,
        start: patient.treatmentPlan.startDate,
        end: patient.treatmentPlan.endDate,
      })
      
      const [correctGeneratedReport, cellsWidth, rowToMerge] = generateReport(patient, result, exercisesResult);
      const workSheet = XLSX.utils.aoa_to_sheet(correctGeneratedReport);
      const workBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workBook, workSheet, 'Sheet 1');

      const patientNameForFile =
        patient.firstName && patient.lastName
          ? `${patient.firstName} ${patient.lastName}`
          : patient.id ?? Date.now();

      // Merge 'Treatment data' header cells
      if (rowToMerge) {
        workSheet['!merges'] = [XLSX.utils.decode_range(`A${rowToMerge}:M${rowToMerge}`)];
      }

      // Configure correct width for columns
      workSheet['!cols'] = cellsWidth;

      XLSX.writeFile(workBook, `${patientNameForFile} Report.xlsx`);
    } catch (e) {
      dispatch({
        type: SET_TOAST,
        payload: {
          open: true,
          message: "Couldn't generate report",
          type: ToastType.ERROR,
        }
      })
    } finally {
      setLoading(false);
    }
  };

  const actions = [
    patientStatus === NOT_ACTIVATED
      ? {
          id: 'resend-password',
          name: 'Resend Pass',
          description: 'Send pass',
          icon: <Password />,
          action: sendPassword,
          buttonClassName: classes.password,
        }
      : {
          id: 'view-report',
          name: 'Report',
          description: 'Excel report',
          icon: loading ? (
            <CircularProgress size={25} />
          ) : (
            <div className={classes.linkLoadingWrapper} onClick={getReportData}>
              <Report />
            </div>
          ),
          action: () => {},
          buttonClassName: classes.report,
        },
    {
      id: 'external-link',
      name: 'External Link',
      description: 'Some small',
      icon: externalLink ? (
        <Link
          className={classes.externalLink}
          to={externalLink}
          target="_blank"
          rel="noopener noreferrer">
          <ExternalLink />
        </Link>
      ) : (
        <ExternalLink className={classes.disabledExternalLink} />
      ),
      action: () => {},
      buttonClassName: classes.link,
    },
    {
      id: 'edit-patient',
      name: 'Edit Patient Details',
      description: 'Change the data',
      icon: <EditPatient />,
      action: editPatient,
      buttonClassName: classes.edit,
    },
    {
      id: 'delete-patient',
      name: 'Delete patient',
      description: 'Delete Account',
      icon: <DeletePatient />,
      action: deletePatient,
      buttonClassName: classes.delete,
    },
    {
      id: 'alarms',
      name: 'Alarms',
      description: '0 alarms',
      icon: <AlarmsMenu />,
      action: showAlarms,
      buttonClassName: classes.alarms,
    },
  ];

  const alarmsText = useMemo(() => {
    if (activeNotificationCount === 1) return '1 alarm';

    return `${activeNotificationCount} alarms`;
  }, [activeNotificationCount]);

  return (
    <div className={classes.controlsContainer}>
      <div className={classes.navigationControls}>
        <IconButton className={classes.back} onClick={() => navigateBack()}>
          <LeftChevron />
        </IconButton>
        <h2>All Patients</h2>
      </div>

      <div className={classes.controls}>
        {actions.map((action) => (
          <ActionButton
            {...action}
            disabled={action.id === 'external-link' && !externalLink}
            description={action.id === 'alarms' ? alarmsText : action.description}
            key={action.id}
          />
        ))}
      </div>
    </div>
  );
};
